<template>
  <div class="page-wrap">
    <div class="section-wrap">
      <div class="wrapper-left">
        <breadcrumbs :crumbs="pageData.crumbs"></breadcrumbs>
        <div class="wrapper-content">
          <div class="wrapper-list">
            <div class="poster">
              <img :src="state.groupAvatar" />
            </div>
            <div class="info">
              <h3>{{ state.groupName }}</h3>
              <div class="block" v-if="state.projectId">
                <div class="tag">关联项目</div>
                <div class="cont"><router-link :to="`/project/${state.projectId}`">{{ state.projectName }}</router-link></div>
              </div>
              <div class="block" v-if="state.projectId">
                <div class="tag">群宗旨</div>
                <div class="cont">{{ state.groupPurpose }}</div>
              </div>
              <div class="block" v-if="state.projectId">
                <div class="tag">群要求</div>
                <div class="cont">{{ state.groupAsk }}</div>
              </div>

              <div class="block block-qrcode" v-if="state.projectId">
                <div class="tag">入群二维码</div>
                <div class="cont">
                  <img :src="state.groupQrCode" />
                </div>
              </div>
            </div>
          </div>

          <div class="wrapper-right">
            <QuickPublishProject></QuickPublishProject>
            <BlockSuggestWXGroup class="box-suggest"></BlockSuggestWXGroup>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, defineAsyncComponent, computed,watch } from 'vue'
import { useRoute } from 'vue-router'
import { Options, Vue } from 'vue-class-component'
import ItemProject from '@/components/ItemProject.vue'
import QuickPublishProject from '@/components/QuickPublishProject.vue'
import BlockSuggestWXGroup from '@/components/BlockSuggestWXGroup.vue'
import { Api } from '@/services/http'

const rate = ref(0)
const route = useRoute()
let pageData = reactive({
  info: {},
  crumbs: [],
})
let state = computed(() => pageData.info)
const projectId = computed(()=>{
  return route.params.id;
})
watch( projectId, (newId)=>{
  console.log('newId',newId)
  mounted();
})


const mounted = async () => {
  const id = route.params.id
  const info = await Api.get('/client-api/resourceGroup/get?id=' + id)
  pageData.info = { ...info }
  rate.value = info.userStarLevel
  pageData.crumbs = [
    {
      name: info.groupName,
    },
  ]
}
mounted()
</script>
<style lang="scss" scoped>
.section-wrap {
  padding-bottom: 30px;
  .wrapper-left {
    padding-top: 30px;
  }
  .wrapper-content {
    display: flex;
    .wrapper-right {
      flex-basis: 260px;
      margin-left: 30px;
    }
    .box-suggest {
      margin-top: 20px;
    }
  }
  .wrapper-list {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    display: flex;
    h3{
      padding-bottom: 20px;
      font-size: 30px;
    }
  }
  .wrapper-page {
    padding: 20px 0;
  }
  .poster {
    width: 240px;
    height: 240px;
    border: 1px solid #ededed;
    border-radius: 20px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 20px;
    }
  }
  .info {
    flex: 1;
    text-align: left;
    padding-left: 20px;
    .title {
      margin-bottom: 20px;
    }
  }
  .block {
    text-align: left;
    margin-bottom: 15px;
    .tag {
      height: 16px;
      border-left: 5px solid #fc9507;
      line-height: 16px;
      padding-left: 10px;
      font-weight: bold;
      
    }
    
    .cont {
      padding: 10px;
      font-size: 14px;
    }
  }
  .block-qrcode{
    img{
      width:145px;height:145px;
    }
  }
}
</style>
